<template>
  <div class="agreements-item">
    <div class="wrapper-img">
      <img :src="require(`@/assets/img/products/${product.img}`)">
    </div>
    <div class="content mb-m">
      <div class="title fs--medium fw--bold">
        {{ product.title }}
      </div>
    </div>
    <app-link v-if="product.policy" class="mb-m" to="/privacy-policy">
      Соглашение
    </app-link>
    <app-link v-if="product.agreement" to="/">
      Соглашение (Мое время и Финрадар)
    </app-link>
  </div>
</template>

<script>
import AppLink from '@/components/common/AppLink'

export default {
  name: 'AgreementItem',
  components: {
    AppLink
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .agreements-item {
    position: relative;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    @include max-w-xs {
      max-width: 280px;
    }
    .wrapper-img {
      position: absolute;
      width: 280px;
      height: 180px;
      align-self: center;
      z-index: 1;
      filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.16));
      @include max-w-xs {
        width: 220px;
        height: 140px;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      margin-top: 100px;
      padding: 90px 16px 16px 16px;
      margin-bottom: 20px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
      border-radius: $border-radius-large;
      @include max-w-xs {
        padding: 60px 16px 16px 16px;
      }
      .title {
        margin-bottom: 4px;
      }
    }
  }
</style>
