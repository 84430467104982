<template>
  <div class="bg-home" :class="activeSection">
    <div class="violet" />
    <div class="orange" />
    <div class="blue" />
    <div class="green" />
  </div>
</template>

<script>
export default {
  name: 'HomeBackground',
  props: {
    activeSection: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg-home {
    position: absolute;
    width: 100vw;
    height: 100vh;
    filter: blur(60px);
    @include max-w-xs {
      filter: blur(40px);
    }
    div {
      border-radius: 40%;
      transition: 1s;
      position: absolute;
      &.violet {
        width: 20vw;
        height: 40vh;
        background: linear-gradient(180deg, #E49CB6 0%, #BC69BE 50.52%, #B481BC 100%);
        opacity: 0.8;
        right: 0;
        bottom: 20%;
      }
      &.orange {
        width: 15vw;
        height: 20vh;
        background: linear-gradient(164.47deg, #EE8D7F 6.6%, #F5AA7D 48.3%, #F0B2A1 89.13%);
        opacity: .8;
        right: 10%;
        bottom: 50%;
      }
      &.blue {
        width: 20vw;
        height: 35vh;
        background: linear-gradient(174.19deg, #4DA1D3 13.31%, #53AED8 54.77%, #B0DFE8 104.8%);
        opacity: 0.4;
        right: 5%;
        bottom: -5%;
      }
      &.green {
        width: 20vw;
        height: 40vh;
        background: linear-gradient(180deg, #5DF475 0%, rgba(238, 242, 31, 0.74) 100%);
        opacity: 0;
        right: 0;
        bottom: 20%;
      }
    }
    &.home {
      .violet {
        width: 20vw;
        height: 40vh;
        background: linear-gradient(180deg, #E49CB6 0%, #BC69BE 50.52%, #B481BC 100%);
        opacity: 0.8;
        right: 0;
        bottom: 20%;
      }
      .orange {
        width: 15vw;
        height: 20vh;
        background: linear-gradient(164.47deg, #EE8D7F 6.6%, #F5AA7D 48.3%, #F0B2A1 89.13%);
        opacity: .8;
        right: 10%;
        bottom: 50%;
      }
      .blue {
        width: 20vw;
        height: 35vh;
        background: linear-gradient(174.19deg, #4DA1D3 13.31%, #53AED8 54.77%, #B0DFE8 104.8%);
        opacity: 0.4;
        right: 5%;
        bottom: -5%;
      }
    }
    &.how {
      .violet {
        opacity: 0.6;
        right: 20%;
        bottom: 50%;
      }
      .orange {
        width: 20vw;
        height: 60vh;
        right: 40%;
        bottom: 10%;
      }
      .blue {
        height: 35vh;
        right: 90%;
        bottom: 5%;
      }
    }
    &.products {
      .violet {
        opacity: 0.4;
        right: 40%;
        bottom: 30%;
      }
      .orange {
        width: 60vw;
        height: 40vh;
        background: linear-gradient(164.47deg, #EE8D7F 6.6%, #F5AA7D 48.3%, #F0B2A1 89.13%);
        opacity: .4;
        right: 60%;
        bottom: 10%;
      }
      .blue {
        opacity: 0;
      }
      .green {
        opacity: .4;
        right: 10%;
        bottom: 10%;
      }
    }
    &.about {
      .orange {
        right: -10%;
        bottom: 70%;
        width: 40vw;
        height: 60vh;
        opacity: 0.4;
      }
      .blue {
        bottom: 70%;
        right: 40%;
        opacity: 0.2;
      }
      .violet {
        opacity: 0;
      }
    }
    &.stats {
      .orange {
        right: 75%;
        bottom: 30%;
      }
      .blue {
        right: 0;
        bottom: -15%;
      }
      .violet {
        background: linear-gradient(164.47deg, #53F9EF 6.6%, #EE7F7F 40.13%, #F0B2A1 89.13%);
        width: 30vw;
        opacity: 0.4;
      }
      .green {
        bottom: 40%;
        right: 5%;
        opacity: 0.4;
        width: 30vw;
        height: 50vh;;
      }
    }
    &.footer {
      .orange {
        width: 20vw;
        height: 30vh;
        right: 40%;
        bottom: 50%;
        opacity: 0.6;
      }
      .violet, .blue {
        opacity: 0;
      }
    }
  }
</style>