<template>
  <main-template>
    <section class="home-agreements">
      <home-background active-section="products" />
      <div class="container">
        <div class="wrapper mb-xl">
          <h1 class="section-title ta--center">
            Соглашения
          </h1>
          <div class="divider" />
          <p class="ta--center">
            Выберите продукт и соответствующие соглашения к нему.
          </p>
        </div>
      </div>
      <swiper
        v-if="products.length"
        ref="swiper"
        class="swiper mb-l"
        :class="{ padding : products.length > 2 }"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="item in products"
          :key="item.id"
          class="product"
          :product="item"
        >
          <agreements-item :product="item" />
        </swiper-slide>
      </swiper>
    </section>
  </main-template>
</template>

<script>
import HomeBackground from '@/components/home/inner/HomeBackground'
import MainTemplate from '@/layouts/MainTemplate'
import AgreementsItem from '@/components/agreements/AgreementsItem'

export default {
  name: 'HomeAgreements',
  components: {
    HomeBackground,
    MainTemplate,
    AgreementsItem
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 40,
        freeMode: true,
        breakpoints: {
          576: {
            slidesPerView: 3,
            spaceBetween: 120
          }
        }
      },
      products: [
        {
          id: 1,
          img: 'keys.png',
          title: 'Ключи на месте',
          policy: true,
          agreement: true

        },
        {
          id: 2,
          img: 'passport.png',
          title: 'Защита документов',
          policy: true
        },
        {
          id: 3,
          img: 'birka.png',
          title: 'Любимый друг',
          policy: true,
          agreement: true
        },
        {
          id: 4,
          img: 'keys.png',
          title: 'Ключи на месте',
          policy: true,
          agreement: true
        },
        {
          id: 5,
          img: 'birka.png',
          title: 'Любимый друг',
          policy: true,
          agreement: true
        }
      ]
    }
  },
  created () {
    this.swiperOptions.centeredSlides = this.products.length < 3
  }
}
</script>

<style lang="scss" scoped>
  .home-agreements {
    min-height: 100vh;
    .container {
      .wrapper {
        max-width: 864px;
      }
    }
    .swiper {
      &.padding {
        padding-left: 120px;
      }
      .product {
        max-width: 305px;
      }
    }
  }
</style>
